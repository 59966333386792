<template>
  <v-card dark tile :loading="loading">
    <v-container fluid class="py-5">
      <v-form ref="form" lazy-validation>
        <v-row align="center">
          <!-- Nombre -->
          <v-col class="d-flex" cols="12">
            <v-text-field
              v-model="equipment.name"
              :counter="50"
              label="Nombre de equipo"
              hint="Nombre de equipo"
              persistent-hint
              required
              solo-inverted
              :rules="[rules.required, rules.counter50]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-5 mb-0 mx-1">
          <v-spacer></v-spacer>
          <v-btn
            plain
            :to="{ name: 'Company', params: { companyId: companyId } }"
          >
            Cancelar
          </v-btn>
          <v-btn @click="save" color="#0049a0"> Guardar </v-btn>
        </v-row>
      </v-form>
    </v-container>

    <v-overlay :value="uploading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>
<script>
export default {
  name: "Equipment",
  data: (vm) => ({
    equipment: {
      name: "",
    },
    rules: {
      required: (value) => !!value || "Requerido.",
      counter50: (value) => value.length <= 50 || "50 carácteres máximos",
    },
  }),
  created() {
    this.initData();
  },
  computed: {
    companyId() {
      return this.$route.params.companyId;
    },
    loading() {
      return this.$store.state.loadingEquipments;
    },
    uploading() {
      return this.$store.state.uploadingEquipment;
    },
  },
  watch: {
    loading(v) {
      if (v == false && this.$store.state.selectedEquipment) {
        this.equipment = this.$store.state.selectedEquipment;
      }
    },
  },
  methods: {
    save() {
      if (this.$refs.form.validate()) {
        let data = {};
        data.equipment = this.equipment;
        data.equipment.companyId = this.$route.params.companyId;
        data.equipmentId = this.$route.params.equipmentId;
        this.$store.dispatch("updateEquipment", data);
      }
    },
    initData() {
      if (!!this.$route.params.equipmentId) {
        this.$store.dispatch("selectEquipment", this.$route.params.equipmentId);
      }
    },
  },
};
</script>

<style>
</style>