var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-iterator',{attrs:{"items":_vm.items,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"search":_vm.search,"hide-default-footer":"","loading-text":"Cargando...","no-data-text":"No hay datos para mostrar","no-results-text":"No se encontraron resultados","sort-by":_vm.sortBy,"sort-desc":true},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('v-toolbar',{attrs:{"dark":"","rounded":""}},[_c('v-text-field',{attrs:{"clearable":"","flat":"","solo-inverted":"","hide-details":"","prepend-inner-icon":"mdi-magnify","label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"ml-4",attrs:{"text":"","to":{
            name: 'New Service',
            params: { companyId: _vm.companyId, equipmentId: _vm.equipmentId },
          }}},[_vm._v(" Nuevo ")])],1),_c('v-progress-linear',{attrs:{"indeterminate":"","rounded":"","active":_vm.loading}}),_c('v-card-title',{staticClass:"px-0"},[_vm._v(" Servicios del equipo: "+_vm._s(_vm.nameEquipment)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"to":{ name: 'Company', params: { companyId: _vm.companyId } }}},[_vm._v(" Volver a equipos ")])],1)]},proxy:true},{key:"default",fn:function(props){return [_c('v-card',[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Cliente")]),_c('th',{staticClass:"text-left"},[_vm._v("Número de conforme")]),_c('th',{staticClass:"text-left"},[_vm._v("Fecha")]),_c('th',{staticClass:"text-left"},[_vm._v("Id")])])]),_c('tbody',[_vm._l((props.items),function(item){return _c('v-menu',{key:item.id,staticStyle:{"max-width":"600px"},attrs:{"absolute":"","offset-y":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('tr',_vm._g(_vm._b({},'tr',attrs,false),on),[_c('td',[_vm._v(_vm._s(item.client))]),_c('td',[_vm._v(_vm._s(item.number))]),_c('td',[_vm._v(_vm._s(_vm.formatDate(item.date)))]),_c('td',[_vm._v(_vm._s(item.id))])])]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){$event.stopPropagation();_vm.serviceSelected = item;
                    _vm.editService();}}},[_c('v-list-item-title',[_vm._v("Editar")])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){$event.stopPropagation();_vm.openDeleteDialog = true;
                    _vm.serviceSelected = item;}}},[_c('v-list-item-title',[_vm._v("Eliminar")])],1)],1)],1)}),_c('v-dialog',{attrs:{"dark":"","max-width":"290"},model:{value:(_vm.openDeleteDialog),callback:function ($$v) {_vm.openDeleteDialog=$$v},expression:"openDeleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" ¿Estás seguro? ")]),_c('v-card-text',[_vm._v(" Eliminar servicio con patente: "),_c('b',[_vm._v(_vm._s(_vm.serviceSelected.patent))]),_vm._v(". "),_c('br'),_vm._v(" Esta acción no se puede deshacer. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.openDeleteDialog = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.openDeleteDialog = false;
                      _vm.deleteService();}}},[_vm._v(" Continuar ")])],1)],1)],1)],2)])],1)]}},{key:"footer",fn:function(){return [_c('v-toolbar',{staticClass:"mt-3 mb-2",attrs:{"rounded":"","align":"center","dark":"","justify":"center"}},[_c('span',{staticClass:"white--text pagination d-none d-sm-block"},[_vm._v("Por página")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pagination",attrs:{"dark":"","plain":"","small":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.itemsPerPage)+" "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('v-list',_vm._l((_vm.itemsPerPageArray),function(number,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.updateItemsPerPage(number)}}},[_c('v-list-item-title',[_vm._v(_vm._s(number))])],1)}),1)],1),_c('v-spacer'),_c('span',{staticClass:"mr-2 white--text pagination"},[_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Página ")]),_vm._v(" "+_vm._s(_vm.page)+" de "+_vm._s(_vm.numberOfPages)+" ")]),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":_vm.formerPage}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('v-btn',{staticClass:"mr-1",attrs:{"icon":"","small":""},on:{"click":_vm.nextPage}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }